// var aesjs = require('aes-js');
/**
 * Session Helper for the application.
 */
// import cookie from 'react-cookies';

/**
 * Sets the current session context for the application.
 * @param data
 */
export function setContext(json, profile) {
  localStorage.setItem("access_token", json.access_token);
  localStorage.setItem("refresh_token", json.refresh_token);
  // localStorage.setItem('role', json.roles_permissions_data.role_name);
  localStorage.setItem("isLoggedIn", true);
  localStorage.setItem("userName", profile.getName());
  localStorage.setItem("userProfile", profile.getImageUrl());
  localStorage.setItem("email", profile.getEmail());
  // localStorage.setItem(
  //   "acs",
  //   JSON.stringify(json.roles_permissions_data.permissions_set)
  // );
}

export function setIdToken(token) {
  localStorage.setItem("tokenId", token);
}

export function setToken(token) {
  localStorage.setItem("access_token", token);
}

/**
 * Removes the current session context of the application.
 * @param url
 */
export function removeContext() {
  localStorage.removeItem("token");
  localStorage.removeItem("isLoggedIn");
  localStorage.removeItem("userName");
  localStorage.removeItem("first_name");
  localStorage.removeItem("last_name");
}

export function getUserName() {
  return localStorage.getItem("userName") || "";
}

export function getUserInfo() {
  return (
    `${localStorage.getItem("first_name")} ${localStorage.getItem(
      "last_name"
    )}` || ""
  );
}

export function getUserProfile() {
  return localStorage.getItem("userProfile") || "";
}

export function getCompanyName() {
  return localStorage.getItem("company_name") || "";
}

export function getCompanyId() {
  return localStorage.getItem("company_id") || "";
}

export function getUserId() {
  return localStorage.getItem("user_id") || "";
}

export function getEmail() {
  return localStorage.getItem("email") || "";
}

export function getToken() {
  return localStorage.getItem("token") || null;
}

export function getRefreshToken() {
  return localStorage.getItem("refresh_token") || null;
}

export function getAccountClass() {
  return localStorage.getItem("account_class") || null;
}

export function getUserType() {
  return localStorage.getItem("user_type" || null);
}

export function isLoggedIn() {
  if (getToken() && localStorage.getItem("isLoggedIn")) {
    return true;
  }
  return false;
}

export function setLastPath(path) {
  localStorage.setItem("lastPath", path);
}

export function getLastPath() {
  return localStorage.getItem("lastPath");
}

export function getPermissions() {
  return JSON.parse(localStorage.getItem("acs"));
}

export function userHasPermisson(action) {
  var permissions = JSON.parse(localStorage.getItem("acs"));
  if (permissions.includes(action) || permissions.includes("*")) {
    return true;
  } else {
    return false;
  }
}

import React from "react";
import { Redirect, Route } from "react-router-dom";
import * as SessionHelper from "./../shared/SessionHelper";

function ProtectedRoute({ component: Component, ...restOfProps }) {
  return (
    <Route
      {...restOfProps}
      render={(props) =>
        SessionHelper.isLoggedIn() ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
}

export default ProtectedRoute;

import React, { Suspense } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import ProtectedRoute from "./components/ProtectedRoute";
import "./App.scss";

toast.configure();

// Containers
const Home = React.lazy(() => import("./containers/TheLayout"));
const Login = React.lazy(() => import("./pages/login/Login"));
const Privacy = React.lazy(() => import("./pages/privacy/privacy.js"));
const Register = React.lazy(() => import("./pages/signup/Register.js"));
const PasswordReset = React.lazy(() =>
  import("./pages/forgot-password/ForgotPassword")
);

function App() {
  return (
    <Router>
      <Suspense
        fallback={
          <div className="text-center mt-5 mb-5">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <div>Loading...Wait..</div>
          </div>
        }
      >
        <Route exact path="/login" component={Login} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/password_reset" component={PasswordReset} />
        <Route exact path="/privacy" component={Privacy} />
        <ProtectedRoute exact path="/" component={Home} />
        <ProtectedRoute exact path="/user-privacy" component={Home} />
        <ProtectedRoute exact path="/home" component={Home} />
        <ProtectedRoute exact path="/profile" component={Home} />
        <ProtectedRoute
          exact
          path="/keyword-calculation/:file_uuid"
          component={Home}
        />
      </Suspense>
    </Router>
  );
}

export default App;
